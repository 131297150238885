import Centre from "./models/Centre";

const tShirtCenters = [
    Centre.Adelaide,
    Centre.Berri,
    Centre.AliceSprings,
    Centre.Albury,
    Centre.Ballarat,
    Centre.BrokenHill,
    Centre.Carins,
    Centre.Darwin,
    Centre.Dubbo,
    Centre.Gatton,
    Centre.GoldCoast,
    Centre.Griffith,
    Centre.Geelong,
    Centre.Hobart,
    Centre.Launceston,
    Centre.Mildura,
    Centre.Newcastle,
    Centre.Brisbane,
    Centre.BrisbaneNorth,
    Centre.Canberra,
    Centre.MelbourneEast,
    Centre.MelbourneSouth,
    Centre.MelbourneWest,
    Centre.MelbourneMandir,
    Centre.Orange,
    Centre.PerthMandir,
    Centre.PerthSouth,
    Centre.Shepparton,
    Centre.SunshineCoast,
    Centre.Sydney,
    Centre.Toowoomba,
    Centre.Townsville,
    Centre.WaggaWagga,
    Centre.Auckland,
    Centre.Christchurch,
    Centre.Hamilton,
    Centre.Rotorua,
    Centre.Wellington
];

const tShirtsWithFijiCenters = [...tShirtCenters, Centre.Ba, Centre.Nadi, Centre.Suva];

export default function getCentersForEventGroup(eventGroupName: string): Centre[] {
    const loweredEventGroupName = eventGroupName?.toLocaleLowerCase();
    switch (loweredEventGroupName) {
        case "anz-kishor-kishori-shibir":
            return [
                Centre.Sydney,
                Centre.MelbourneMandir,
                Centre.MelbourneSouth,
                Centre.MelbourneWest,
                Centre.Brisbane,
                Centre.Canberra,
                Centre.Adelaide,
                Centre.Perth,
                Centre.PerthMandir,
                Centre.PerthSouth,
                Centre.Darwin
            ];
        case "bal-balika-exam-2024" :
            return  [
                Centre.Adelaide,
                Centre.Albury,
                Centre.Balwyn,
                Centre.Brisbane,
                Centre.Canberra,
                Centre.Cranbourne,
                Centre.Darwin,
                Centre.Geelong,
                Centre.GoldCoast,
                Centre.Hobart,
                Centre.MelbourneMandir,
                Centre.MelbourneSouth,
                Centre.MelbourneWest,
                Centre.Perth,
                Centre.Shepparton,
                Centre.Sydney,
            ];
        case "annakut-2024":
        case "annakut-2023" :
           return [
               Centre.Adelaide,
               Centre.Albury,
               Centre.Ballarat,
               Centre.Bendigo,
               Centre.Brisbane,
               Centre.BrisbaneNorth,
               Centre.Canberra,
               Centre.Darwin,
               Centre.Geelong,
               Centre.GoldCoast,
               Centre.Griffith,
               Centre.Hobart,
               Centre.MelbourneMandir,
               Centre.MelbourneSouth,
               Centre.MelbourneWest,
               Centre.MelbourneEast,
               Centre.Newcastle,
               Centre.PerthMandir,
               Centre.PerthSouth,
               Centre.Shepparton,
               Centre.Sydney,
               Centre.Toowoomba
            ];
        case "gold-coast-mandir-mahotsav-t-shirts":
            return [
                Centre.GoldCoast
        ];
        case "sunshine-coast-2025": 
            return [
                Centre.SunshineCoast
        ];
        case "akshardham-bhumi-pujan-t-shirts": 
            return tShirtCenters;
        case "fuldol-2025-t-shirts":
            return tShirtsWithFijiCenters;
        case "msm-vicharan-2025": 
            return [
                Centre.International,
                Centre.Adelaide,
                Centre.Albury,
                Centre.Brisbane,
                Centre.Balwyn,
                Centre.Canberra,
                Centre.Dubbo,
                Centre.Darwin,
                Centre.GoldCoast,
                Centre.MelbourneMandir,
                Centre.MelbourneWest,
                Centre.MelbourneSouth,
                Centre.Mittagong,
                Centre.Perth,
                Centre.Hobart,
                Centre.Sydney,
                Centre.Gatton,
                Centre.Griffith,
                Centre.Geelong,
                Centre.Gosford,
                Centre.Newcastle,
                Centre.Shepparton,
                Centre.Tamworth,
                Centre.Toowoomba,
                Centre.PerthMandir,
                Centre.PerthSouth,
                Centre.Auckland,
                Centre.Wellington,
                Centre.Christchurch
            ];
    };

    return [
        Centre.International,
        Centre.Adelaide,
        Centre.Albury,
        Centre.Brisbane,
        Centre.Balwyn,
        Centre.Canberra,
        Centre.Dubbo,
        Centre.Darwin,
        Centre.GoldCoast,
        Centre.MelbourneMandir,
        Centre.MelbourneWest,
        Centre.MelbourneSouth,
        Centre.Mittagong,
        Centre.Perth,
        Centre.Hobart,
        Centre.Sydney,
        Centre.Gatton,
        Centre.Griffith,
        Centre.Geelong,
        Centre.Gosford,
        Centre.Newcastle,
        Centre.Shepparton,
        Centre.Tamworth,
        Centre.Toowoomba,
        Centre.PerthMandir,
        Centre.PerthSouth,
        Centre.Auckland,
        Centre.Wellington,
        Centre.Christchurch
    ];
}
